import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import '../../index.css';
import { Navbar, Nav, Container, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Advisory = () => {
  const [expanded, setExpanded] = useState(true);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpen2, setDropdownOpen2] = useState(false);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleMouseEnter2 = () => {
    setDropdownOpen2(true);
  };

  const handleMouseLeave2 = () => {
    setDropdownOpen2(false);
  };

  return (
    <>
      <Navbar
        bg='transparent'
        expand='lg'
        className='custom-navbar'
        expanded={expanded} // Control expanded state
        onToggle={() => setExpanded(!expanded)} // Toggle the state when clicking on the Navbar.Toggle
      >
        <Navbar.Brand
          as={Link}
          to='/'
          style={{ color: 'white', fontWeight: 'bold', marginTop: '-6px' }}
        >
          &Theta;
        </Navbar.Brand>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: '#177b9c',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-7px',
              }}
              as={Link}
              to='/Advisory'
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#177b9c')}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = 'transparent')
              }
            >
              Theta Advisory
            </Button>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-5px',
              }}
              as={Link}
              to='/Solution'
            >
              Theta Solution
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Nav.Link as={Link} to='/Advisory/ProjectExpertise'>
                <div className='dropdown'>
                  <Dropdown
                    show={isDropdownOpen}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = '#177b9c')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = 'transparent')
                      }
                    >
                      ESG Data Project Expertise
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                      show={isDropdownOpen}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Financial Institutions
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionNonFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Non-Financial
                        Institutions
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to='/Advisory/Boosting'>
                <div
                  className='dropdown'
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = '#177b9c')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = 'transparent')
                  }
                >
                  <Dropdown
                    show={isDropdownOpen2}
                    onMouseEnter={handleMouseEnter2}
                    onMouseLeave={handleMouseLeave2}
                  >
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      Boosting Your Energy Qualifications
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/ISO'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ISO Certification Training
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Audit'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Audit and Consulting Services
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Energy'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Energy & Environmental Management 
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <Container>
        <br />
        <br />
        <br />
        <br />
        <div>
          <h4>Achieving Your Objectives Together:</h4>
          <h5> ESG Data Project Expertise: </h5>
          <ul>
            <li>ESG Data Project Solutions for Financial Institutions</li>
            <li>ESG Data Project Solutions for Non-Financial Institutions</li>
          </ul>
          <h5> Boosting Your Energy Qualifications: </h5>
          <ul>
            <li>ISO Certification Training</li>
            <li>Audit and Consulting Services</li>
            <li>Energy & Environmental Management </li>
          </ul>
        </div>
      </Container>
      <Helmet>
        <title>Theta Fintech AI - Theta Advisory </title>
        <meta name='description' content='Theta Fintech AI - Theta Advisory' />
        <link
          rel='canonical'
          href='https://thetafintechai.netlify.app/Advisory'
        />
      </Helmet>
    </>
  );
};

export default Advisory;
