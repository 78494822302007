import React from 'react';
import { Container } from 'react-bootstrap';
import Advisory from './Advisory';

const ExpertiseAdvisory = () => {
  return (
    <>
      <Advisory />

      <Container>
        <br />
        <br />
        <br />
        <div>
          <h4>Achieving Your Objectives Together:</h4>
          <h5> ESG Data Project Expertise: </h5>
          <ul>
            <li>ESG Data Project Solutions for Financial Institutions</li>
            <li>ESG Data Project Solutions for Non-Financial Institutions</li>
          </ul>
          <h5> Achieve Your Sustainable Performance Goals: </h5>
          <ul>
            <li>ISO Certification Training</li>
            <li>Audit and Consulting Services</li>
            <li>Energy & Environmental Management </li>
          </ul>
        </div>
      </Container>
    </>
  );
};

export default ExpertiseAdvisory;
