import React, { useState } from 'react';
import { Navbar, Nav, Container, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Audit = () => {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <Navbar
        bg='transparent'
        expand='lg'
        className='custom-navbar'
        expanded={expanded} // Control expanded state
        onToggle={() => setExpanded(!expanded)} // Toggle the state when clicking on the Navbar.Toggle
      >
        <Navbar.Brand
          as={Link}
          to='/'
          style={{ color: 'white', fontWeight: 'bold', marginTop: '-6px' }}
        >
          &Theta;
        </Navbar.Brand>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: '#177b9c',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-7px',
              }}
              as={Link}
              to='/Advisory'
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#177b9c')}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = 'transparent')
              }
            >
              Theta Advisory
            </Button>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-5px',
              }}
              as={Link}
              to='/Solution'
            >
              Theta Solution
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Collapse
            id='basic-navbar-nav'
            expanded={expanded}
            onToggle={() => setExpanded(!expanded)}
          >
            <Nav className='mr-auto'>
              <Nav.Link as={Link} to='/Advisory/ProjectExpertise'>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = '#177b9c')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = 'transparent')
                      }
                    >
                      ESG Data Project Expertise
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Financial Institutions
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionNonFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Non-Financial
                        Institutions
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to=''>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: '#177b9c',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      Achieve Your Sustainable Performance Goals
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/ISO'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ISO Certification Training
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Audit'
                        style={{
                          backgroundColor: '#177b9c',
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                      >
                        Audit and Consulting Services
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Energy'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Energy & Environmental Management 
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <br />
        <br />
        <br />
        <br />
        <Container>
          <h5>
        Ensure Your System’s Compliance with ISO Energy and Environmental Standards

          </h5>
          <ul>
            <li>
At Theta, we understand the importance of maintaining compliance with ISO standards like 14001 and 50001 to optimize energy and environmental management while supporting sustainable business practices. 
Our expert consultants are dedicated to assessing your current systems and ensuring alignment with these international standards.

            </li>
          </ul>
          <h5>Thorough Compliance Assessment</h5>
          <ul>
            <li>
We start with a detailed evaluation of your energy and environmental management systems to identify gaps and areas of non-compliance. With extensive expertise in ISO 14001, ISO 50001, and sustainability management, our consultants ensure a thorough review of all critical aspects of your operations.

            </li>
          </ul>
          <h5>Actionable Advisory Report</h5>
          <ul>
            <li>
After completing our assessment, we provide a comprehensive advisory report that highlights findings and identifies specific areas of improvement. 
This report delivers tailored, practical recommendations to bridge compliance gaps and enhance system performance, empowering your organization to achieve full ISO certification and beyond.

            </li>
          </ul>
          <h5>Expert Guidance and Ongoing Support</h5>
          <ul>
            <li>
Our service doesn’t stop at delivering recommendations. We collaborate with your team to implement the suggested improvements, offering continued guidance to ensure your systems remain compliant and achieve ongoing improvements in both energy efficiency and environmental performance.

            </li>
          </ul>
          <h5>Proven Methodologies for Success</h5>
          <ul>
            <li>
With a history of success across various industries, Theta is your trusted partner in achieving compliance with ISO 14001 and 50001. Our proven methodologies and actionable insights ensure that your organization can confidently make decisions that drive sustainable growth and operational excellence.
            </li>
          </ul>
        </Container>
      </div>
    </>
  );
};

export default Audit;
