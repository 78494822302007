import React, { useState } from 'react';
import {  Navbar, Nav, Container, Dropdown,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const SolutionNonFinancial = () => {
  const [expanded, setExpanded] = useState(true); // Initialize to true to keep it open by default

  return (
    <>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Brand
            as={Link}
            to='/'
            style={{ color: 'white', fontWeight: 'bold', marginTop: '-6 px' }}
          >
            &Theta;
          </Navbar.Brand>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Button
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderColor: 'transparent',
                  backgroundColor: '#177b9c',
                  textDecoration: 'underline',
                  width: 'max-content',
                  marginLeft: '-15px',
                }}
                as={Link}
                to='/Advisory'
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = '#177b9c')
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = 'transparent')
                }
              >
                Theta Advisory
              </Button>
              <Button
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderColor: 'transparent',
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                  width: 'max-content',
                  marginLeft: '-5px',
                }}
                as={Link}
                to='/Solution'
              >
                Theta Solution
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Nav.Link as={Link} to=''>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: '#177b9c',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      ESG Data Project Expertise
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                      >
                        ESG Data Project Solutions for Financial Institutions
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionNonFinancial'
                        style={{
                          backgroundColor: '#177b9c',
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                      >
                        ESG Data Project Solutions for Non-Financial
                        Institutions
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to='/Advisory/Boosting'>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = '#177b9c')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = 'transparent')
                      }
                    >
                      Achieve Your Sustainable Performance Goals
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/ISO'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ISO Certification Training
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Audit'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Audit and Consulting Services
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Energy'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Energy & Environmental Management 
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <br />
      <br /> <br />
      <Container>
        <h4>Unlock the Full Potential of Your ESG Data:</h4>
        <hr />
        <h5>
          Unlock the Power of ESG Reporting with Tailored Solutions for
          Non-Financial Institutions
        </h5>
        <p>
          At Theta Advisory, we specialize in streamlining and automating ESG
          (Environmental, Social, and Governance) reporting for businesses.
          Whether you need to meet regulatory requirements, address stakeholder
          expectations, or enhance partner communications, our flexible and
          precise services are designed to meet your unique needs.
        </p>
        <br />
        <strong> Why Choosing Theta? </strong>
        <li>
          <strong> Custom Solutions: </strong> We understand that every
          organization is different. Our services are 100% tailored to fit your
          specific requirements.
        </li>
        <li>
          <strong> Comprehensive Expertise: </strong> From compliance with
          regulations to transparent reporting for shareholders and partners, we
          cover all aspects of ESG reporting.
        </li>
        <li>
          <strong> Efficient Automation: </strong> Leverage our advanced tools
          and strategies to simplify your reporting process and ensure accuracy.
        </li>
        <br />
        <h7>
          {' '}
          Partner with us to transform your ESG reporting into a strategic
          asset. Contact us today to discover how we can help you achieve your
          sustainability goals with ease and efficiency.
        </h7>
      </Container>
    </>
  );
};

export default SolutionNonFinancial;
