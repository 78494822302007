import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './index.css';
import ESG from './components/Solution/ESG';
import Overview from './components/Solution/Overview';
import Data from './components/Solution/Data';
import Research from './components/Solution/Research';
import Main from './components/Main';
import Footer from './components/Footer';
import Whytheta from './components/Solution/Whytheta';
import Sovereign from './components/Solution/Sovereign';
import Solution from './components/Solution/Solution';
import Advisory from './components/Advisory/Advisory';
import ExpertiseAdvisory from './components/Advisory/ExpertiseAdvisory';
import ProjectExpertise from './components/Advisory/ProjectExpertise';
import Boosting from './components/Advisory/Boosting';
import SolutionFinancial from './components/Advisory/SolutionFinancial';
import SolutionNonFinancial from './components/Advisory/SolutionNonFinancial';
import ISO from './components/Advisory/ISO';
import Audit from './components/Advisory/Audit';
import Energy from './components/Advisory/Energy';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  
   const [showSplash, setShowSplash] = useState(true);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  
  useEffect(() => {
  if (showPopup) {
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 7000); // 2000ms = 2 seconds
    
    return () => clearTimeout(timer); // Cleanup timer
  }
}, [showPopup]); // Run effect when showPopup changes

  useEffect(() => {
    // Show splash screen for 3 seconds
    const splashTimer = setTimeout(() => setShowSplash(false), 3000);
    return () => clearTimeout(splashTimer);
  }, []);

  useEffect(() => {
    // Listen for the beforeinstallprompt event
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault(); // Prevent automatic display
      setDeferredPrompt(e); // Save the event for later use
      setShowPopup(true); // Show the popup
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("App installed");
        } else {
          console.log("App installation declined");
        }
        setDeferredPrompt(null); // Reset deferred prompt
        setShowPopup(false); // Hide the popup
      });
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Hide the popup when dismissed
  };

  if (showSplash) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#555", 
        }}
      >
        <img
          src="/theta_new.png"
          alt="Splash Screen"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </div>
    );
  }
  
  

  return (
    <>
      <Router>
        <div className='App'>
          <Routes>
            <Route path='/expertise' element={<Overview />} />
            <Route path='/Corporate' element={<Data />} />
            <Route path='/Research' element={<Research />} />
            <Route path='/whytheta' element={<Whytheta />} />
            <Route path='/Sovereign' element={<Sovereign />} />
            <Route path='/Solution' element={<Solution />} />
            <Route path='/Advisory' element={<Advisory />} />
            <Route path='/ESG' element={<ESG />} />
            <Route
              path='/Advisory/ExpertiseAdvisory'
              element={<ExpertiseAdvisory />}
            />
            <Route
              path='/Advisory/ProjectExpertise'
              element={<ProjectExpertise />}
            />
            <Route path='/Advisory/Boosting' element={<Boosting />} />
            <Route path='/Advisory/Boosting/ISO' element={<ISO />} />
            <Route path='/Advisory/Boosting/Audit' element={<Audit />} />
            <Route path='/Advisory/Boosting/Energy' element={<Energy />} />
            <Route
              path='/Advisory/ProjectExpertise/SolutionNonFinancial'
              element={<SolutionNonFinancial />}
            />
            <Route
              path='/Advisory/ProjectExpertise/SolutionFinancial'
              element={<SolutionFinancial />}
            />

            <Route path='/' element={<Main />} />
            
          </Routes>
        </div>
        {/* Pop-up Install Prompt */}
        {showPopup && (
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              color: '#177b9c',
              backgroundColor:'transparent',
              fontWeight: 'bold',
              marginTop: '-6px',              display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: "999",
            }}
            onClick={handleClosePopup} // Dismiss popup on background click
          >
            <div
              style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              color: '#177b9c',
              backgroundColor:'transparent',
              fontWeight: 'bold',
              marginTop: '-6px',              display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: "999",
              }}
              onClick={(e) => e.stopPropagation()} // Prevent background click
            >
              <h2> </h2>
              <p></p>
              <button
                onClick={handleInstall}
                style={{
                  padding: "10px 20px",
                  color: '#177b9c',
                  backgroundColor:'transparent',
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                  transition: "background-color 0.3s",
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "transparent")}
                onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
              >
                Install
              </button>
              <button
                onClick={handleClosePopup}
                style={{
                  marginTop: "10px",
                  background: "none",
                  border: "none",
                  color: "#888",
                  cursor: "pointer",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Dismiss
              </button>
            </div>
          </div>
        )}
      </Router>
      <div className='footer'>
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
