import React, { useState } from 'react';
import { Navbar, Nav, Container, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const ISO = () => {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <Navbar
        bg='transparent'
        expand='lg'
        className='custom-navbar'
        expanded={expanded} // Control expanded state
        onToggle={() => setExpanded(!expanded)} // Toggle the state when clicking on the Navbar.Toggle
      >
        <Navbar.Brand
          as={Link}
          to='/'
          style={{ color: 'white', fontWeight: 'bold', marginTop: '-6px' }}
        >
          &Theta;
        </Navbar.Brand>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: '#177b9c',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-7px',
              }}
              as={Link}
              to='/Advisory'
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#177b9c')}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = 'transparent')
              }
            >
              Theta Advisory
            </Button>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-5px',
              }}
              as={Link}
              to='/Solution'
            >
              Theta Solution
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Collapse
            id='basic-navbar-nav'
            expanded={expanded}
            onToggle={() => setExpanded(!expanded)}
          >
            <Nav className='mr-auto'>
              <Nav.Link as={Link} to='/Advisory/ProjectExpertise'>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = '#177b9c')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = 'transparent')
                      }
                    >
                      ESG Data Project Expertise
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Financial Institutions
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionNonFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#1c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Non-Financial
                        Institutions
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to=''>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: '#177b9c',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      Achieve Your Sustainable Performance Goals
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/ISO'
                        style={{
                          backgroundColor: '#177b9c',
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                      >
                        ISO Certification Training
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Audit'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Audit and Consulting Services
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Energy'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Energy & Environmental Management 
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <br />
      <br />
      <br />
      <br />
      <Container>
        <h5>Empower Your Team with Comprehensive ISO Energy and Environmental Management Training</h5>
        <ul>
          <li>
At Theta, we understand the vital role that energy and environmental management play in today’s business landscape. That’s why we offer specialized training programs covering ISO standards such as 14001 and 50001, designed to equip your team with the knowledge and skills needed to enhance environmental performance, optimize energy efficiency, and ensure compliance with international standards.

          </li>
        </ul>
        <h5>Tailored to Your Needs</h5>
        <ul>
          <li>
We recognize that every organization is unique. Our training programs are meticulously customized to address your business’s specific challenges and objectives. Whether you’re beginning your ISO journey or aiming to deepen your team’s expertise, we tailor our content to align with your goals, ensuring maximum relevance and impact.

          </li>
        </ul>
        <h5>Expert-Led Training</h5>
        <ul>
          <li>
Our courses are led by industry experts with extensive experience in energy and environmental management and ISO standards. With Theta, your employees will gain both theoretical knowledge and practical insights drawn from real-world applications. This ensures your team is fully equipped to implement ISO standards effectively and drive measurable improvements.

          </li>
        </ul>
        <h5>Intensive Learning, Lasting Results</h5>
        <ul>
          <li>
We value your time, which is why our in-depth training is designed to be both intensive and efficient. Over just four days, we deliver a comprehensive exploration of ISO 14001 and 50001, providing your team with a solid understanding of these standards. Despite the condensed format, our approach ensures lasting results that your business can apply immediately.

          </li>
        </ul>
        <h5>Proven Success</h5>
        <ul>
          <li>
Our track record speaks for itself. Numerous organizations have successfully improved their energy efficiency and environmental impact through our training programs. With Theta, you can trust that your team is receiving world-class education, backed by proven methodologies and a commitment to excellence.
          </li>
        </ul>
      </Container>
    </>
  );
};

export default ISO;
