import React, { useState } from 'react';
import { Navbar, Nav, Container, Dropdown, Col, Row , Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const SolutionFinancial = () => {
  const [expanded, setExpanded] = useState(true); // Initialize to true to keep it open by default

  return (
    <>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Brand
            as={Link}
            to='/'
            style={{ color: 'white', fontWeight: 'bold', marginTop: '-6 px' }}
          >
            &Theta;
          </Navbar.Brand>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Button
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderColor: 'transparent',
                  backgroundColor: '#177b9c',
                  textDecoration: 'underline',
                  width: 'max-content',
                  marginLeft: '-15px',
                }}
                as={Link}
                to='/Advisory'
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = '#177b9c')
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = 'transparent')
                }
              >
                Theta Advisory
              </Button>
              <Button
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderColor: 'transparent',
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                  width: 'max-content',
                  marginLeft: '-5px',
                }}
                as={Link}
                to='/Solution'
              >
                Theta Solution
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Nav.Link as={Link} to=''>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: '#177b9c',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      ESG Data Project Expertise
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionFinancial'
                        style={{
                          backgroundColor: '#177b9c',
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                      >
                        ESG Data Project Solutions for Financial Institutions
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionNonFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Non-Financial
                        Institutions
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to='/Advisory/Boosting'>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = '#177b9c')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = 'transparent')
                      }
                    >
                      Achieve Your Sustainable Performance Goals
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/ISO'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ISO Certification Training
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Audit'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Audit and Consulting Services
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Energy'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Energy & Environmental Management 
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <br />
      <br /> <br />
      <Container>
        <h4>Unlock the Full Potential of Your ESG Data:</h4>
        <hr />
        <h5>
          Structuring Your Extra-Financial Data for Financial Institutions:
        </h5>
        <br />
        <Row>
          <Col md={6}>
            <h6>Leverage Your ESG Data for Competitive Advantage</h6>
            <p>
              We have been working with a prestigious Investment Solution Group,
              managing over $2 trillion in AuM, to enhance their ESG
              (Environmental, Social, and Governance) Data Management Projects.
              Our collaboration involved implementing advanced ESG Data
              solutions within their IT infrastructure. Our contributions
              include:
            </p>
            <ul>
              <li>
                Implementing multiple automation processes to minimize
                operational risks
              </li>
              <li>Enhancing data quality management</li>
              <li>Establishing in-depth controls to address inaccurate data</li>
            </ul>
          </Col>
          <Col md={6}>
            <h6>Pioneering ESG Data Solutions for Financial Institutions</h6>
            <p>
              As a specialized Extra-financial Data start-up, we excel in
              providing comprehensive ESG (Environmental, Social, and
              Governance) data services tailored for financial institutions. Our
              expertise extends beyond the standard ESG pillars, encompassing
              critical areas such as Climate Data, Controversies, and
              Biodiversity. We deliver insightful, data-driven solutions to help
              our clients navigate the complexities of sustainable investing.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SolutionFinancial;
